// Generated by Framer (4b8bcae)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, getPropertyControls, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
import * as sharedStyle from "../css/Hwm_BzrEV";
import * as sharedStyle1 from "../css/QiSWSd_yh";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const cycleOrder = ["NpT0z_bWk", "HZQQYlpXf"];

const serializationHash = "framer-uaG1J"

const variantClassNames = {HZQQYlpXf: "framer-v-9pc4sf", NpT0z_bWk: "framer-v-1hkbisj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Desktop: "NpT0z_bWk", Mobile: "HZQQYlpXf"}

const getProps = ({height, icon, id, label, link, width, ...props}) => { return {...props, LfBuq2Mjw: icon ?? props.LfBuq2Mjw ?? "SpotifyLogo", S_FELeCGs: link ?? props.S_FELeCGs, SvTygQDrz: label ?? props.SvTygQDrz ?? "Spotify", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "NpT0z_bWk"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;label?: string;icon?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, SvTygQDrz, LfBuq2Mjw, S_FELeCGs, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "NpT0z_bWk", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={S_FELeCGs} openInNewTab><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1hkbisj", className, classNames)} framer-cu2cf9`} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"NpT0z_bWk"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({HZQQYlpXf: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1oggf11-container"} layoutDependency={layoutDependency} layoutId={"UJsBQfB_z-container"}><Phosphor color={"var(--token-89adac26-f2dc-4993-bf5f-9ce442361782, rgb(0, 0, 0))"} height={"100%"} iconSearch={"House"} iconSelection={LfBuq2Mjw} id={"UJsBQfB_z"} layoutId={"UJsBQfB_z"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"thin"} width={"100%"}/></motion.div></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.h6 className={"framer-styles-preset-eyqprx"} data-styles-preset={"Hwm_BzrEV"}>Spotify</motion.h6></React.Fragment>} className={"framer-9ebjde"} data-framer-name={"Instagram"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"QZMbnQV9z"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={SvTygQDrz} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({HZQQYlpXf: {children: <React.Fragment><motion.h6 className={"framer-styles-preset-130klns"} data-styles-preset={"QiSWSd_yh"}>Spotify</motion.h6></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-uaG1J.framer-cu2cf9, .framer-uaG1J .framer-cu2cf9 { display: block; }", ".framer-uaG1J.framer-1hkbisj { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: auto; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: auto; }", ".framer-uaG1J .framer-1oggf11-container { aspect-ratio: 1 / 1; flex: none; height: 30px; position: relative; width: var(--framer-aspect-ratio-supported, 30px); }", ".framer-uaG1J .framer-9ebjde { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-uaG1J.framer-1hkbisj { gap: 0px; } .framer-uaG1J.framer-1hkbisj > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-uaG1J.framer-1hkbisj > :first-child { margin-left: 0px; } .framer-uaG1J.framer-1hkbisj > :last-child { margin-right: 0px; } }", ".framer-uaG1J.framer-v-9pc4sf .framer-1oggf11-container { height: 24px; width: var(--framer-aspect-ratio-supported, 24px); }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 86
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"HZQQYlpXf":{"layout":["auto","auto"]}}}
 * @framerVariables {"SvTygQDrz":"label","LfBuq2Mjw":"icon","S_FELeCGs":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerKq9Jkaixc: React.ComponentType<Props> = withCSS(Component, css, "framer-uaG1J") as typeof Component;
export default FramerKq9Jkaixc;

FramerKq9Jkaixc.displayName = "Icon link";

FramerKq9Jkaixc.defaultProps = {height: 30, width: 86};

addPropertyControls(FramerKq9Jkaixc, {variant: {options: ["NpT0z_bWk", "HZQQYlpXf"], optionTitles: ["Desktop", "Mobile"], title: "Variant", type: ControlType.Enum}, SvTygQDrz: {defaultValue: "Spotify", displayTextArea: false, title: "Label", type: ControlType.String}, LfBuq2Mjw: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "SpotifyLogo", description: undefined, hidden: undefined, title: "Icon"}, S_FELeCGs: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerKq9Jkaixc, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...PhosphorFonts, ...getFontsFromSharedStyle(sharedStyle.fonts), ...getFontsFromSharedStyle(sharedStyle1.fonts)], {supportsExplicitInterCodegen: true})